import React, { useState } from 'react';
import PropTypes from 'prop-types';

import HeaderContainer from '../../containers/HeaderContainer';
import ProjectContainer from '../../containers/ProjectContainer';
import BoardActionsContainer from '../../containers/BoardActionsContainer';
import BoardsContainer from '../../containers/BoardsContainer';

import styles from './Fixed.module.scss';

function Fixed({ projectId, board, toggleMenu, setToggleMenu }) {
  const toggle = () => {
    setToggleMenu(!toggleMenu);
  };

  return (
    <div>
      {board && !board.isFetching ? (
        <div className={styles.wrapper}>
          <HeaderContainer toggle={toggle} />
          {projectId && toggleMenu && <ProjectContainer />}
          <BoardActionsContainer />
        </div>
      ) : (
        <div className={styles.wrapperFull}>
          <HeaderContainer />
          {projectId && <ProjectContainer />}
        </div>
      )}
    </div>
  );
}

Fixed.propTypes = {
  projectId: PropTypes.string,
  board: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  toggleMenu: PropTypes.bool,
  setToggleMenu: PropTypes.func,
};

Fixed.defaultProps = {
  projectId: undefined,
  board: undefined,
  toggleMenu: true,
  setToggleMenu: () => {},
};

export default Fixed;
