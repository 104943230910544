import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { Button, Dropdown, Icon, Image, Menu } from 'semantic-ui-react';
import { usePopup } from '../../lib/popup';

import Paths from '../../constants/Paths';
import NotificationsStep from './NotificationsStep';
import UserStep from '../UserStep';
import AngleDown from '../../assets/images/angle-down.svg';
import AngleUp from '../../assets/images/angle-up.svg';
import MenuImg from '../../assets/images/menu.svg';
import logo from '../../assets/images/logo-white.svg';

import styles from './Header.module.scss';

const POPUP_PROPS = {
  position: 'bottom right',
};

const Header = React.memo(
  ({
    project,
    projects,
    user,
    notifications,
    isLogouting,
    canEditProject,
    canEditUsers,
    onProjectSettingsClick,
    onUsersClick,
    onNotificationDelete,
    onUserSettingsClick,
    onLogout,
    toggle,
  }) => {
    const handleProjectSettingsClick = useCallback(() => {
      if (canEditProject) {
        onProjectSettingsClick();
      }
    }, [canEditProject, onProjectSettingsClick]);
    const [openDropdown, setOpenDropdown] = useState(false);

    const NotificationsPopup = usePopup(NotificationsStep, POPUP_PROPS);
    const UserPopup = usePopup(UserStep, POPUP_PROPS);

    return (
      <div className={styles.wrapper}>
        {!project && (
          <Link to={Paths.ROOT} className={classNames(styles.logo)}>
            <Image src={logo} />
          </Link>
        )}
        <Menu inverted size="large" className={styles.menu}>
          {project && (
            <Menu.Menu position="left">
              <Menu.Item onClick={toggle} className={classNames(styles.item, styles.itemHoverable)}>
                <Image src={MenuImg} />
              </Menu.Item>
              <Menu.Item
                as={Link}
                to={Paths.ROOT}
                className={classNames(styles.item, styles.itemHoverable)}
              >
                <Icon fitted name="arrow left" />
              </Menu.Item>
              <Menu.Item className={classNames(styles.item, styles.title)}>
                {project.name}
                {openDropdown && (
                  <div className={styles.dropdownWrapper}>
                    {projects.map((proj) => (
                      <div>
                        <Link
                          to={
                            proj.firstBoardId
                              ? Paths.BOARDS.replace(':id', proj.firstBoardId)
                              : Paths.PROJECTS.replace(':id', proj.id)
                          }
                          onClick={() => setOpenDropdown(!openDropdown)}
                          className={styles.link}
                        >
                          {proj.name}
                        </Link>
                      </div>
                    ))}
                  </div>
                )}
                <Button
                  className={classNames(styles.angleButton, styles.target)}
                  onClick={() => setOpenDropdown(!openDropdown)}
                >
                  <Image src={openDropdown ? AngleUp : AngleDown} />
                </Button>
                {canEditProject && (
                  <Button
                    className={classNames(styles.editButton, styles.target)}
                    onClick={handleProjectSettingsClick}
                  >
                    <Icon fitted name="pencil" size="small" />
                  </Button>
                )}
              </Menu.Item>
            </Menu.Menu>
          )}
          <Menu.Menu position="right">
            {canEditUsers && (
              <Menu.Item
                className={classNames(styles.item, styles.itemHoverable)}
                onClick={onUsersClick}
              >
                <Icon fitted name="users" />
              </Menu.Item>
            )}
            <NotificationsPopup items={notifications} onDelete={onNotificationDelete}>
              <Menu.Item className={classNames(styles.item, styles.itemHoverable)}>
                <Icon fitted name="bell" />
                {notifications.length > 0 && (
                  <span className={styles.notification}>{notifications.length}</span>
                )}
              </Menu.Item>
            </NotificationsPopup>
            <UserPopup
              isLogouting={isLogouting}
              onSettingsClick={onUserSettingsClick}
              onLogout={onLogout}
            >
              <Menu.Item
                className={classNames(styles.item, styles.itemHoverable, styles.marginRight)}
              >
                {user.name}
              </Menu.Item>
            </UserPopup>
          </Menu.Menu>
        </Menu>
      </div>
    );
  },
);

Header.propTypes = {
  /* eslint-disable react/forbid-prop-types */
  project: PropTypes.object,
  user: PropTypes.object.isRequired,
  notifications: PropTypes.array.isRequired,
  /* eslint-enable react/forbid-prop-types */
  isLogouting: PropTypes.bool.isRequired,
  canEditProject: PropTypes.bool.isRequired,
  canEditUsers: PropTypes.bool.isRequired,
  onProjectSettingsClick: PropTypes.func.isRequired,
  onUsersClick: PropTypes.func.isRequired,
  onNotificationDelete: PropTypes.func.isRequired,
  onUserSettingsClick: PropTypes.func.isRequired,
  onLogout: PropTypes.func.isRequired,
  projects: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  toggle: PropTypes.func.isRequired,
};

Header.defaultProps = {
  project: undefined,
};

export default Header;
